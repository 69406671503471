import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Grid,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import Link from "components/ui/Link"
import { Experience, Job, Skill } from "data/cv/model"
import React from "react"
import DateRange from "./DateRange"

const JobSkills = ({ skills, ...props }: { skills: Skill[] } & BoxProps) => {
  const space = { base: 0.5, md: 1 }
  const fsizes = { base: "xs", md: "sm" }
  return (
    <Flex
      flexWrap="wrap"
      flex={1}
      flexDirection="row"
      marginTop={3}
      marginBottom={8}
      {...props}
    >
      {skills.map((s, idx) => (
        <Box
          key={idx}
          borderWidth={0.5}
          borderRadius={6}
          borderColor="gray.200"
          color="gray.600"
          marginX={space}
          marginY={space}
          paddingX={space}
          justifyContent="center"
        >
          {s.link == null ? (
            <Box fontSize={fsizes}>{s.name}</Box>
          ) : (
            <Link display="block" href={s.link} fontSize={fsizes}>
              <Tooltip label={s.tooltip}>{s.name}</Tooltip>
            </Link>
          )}
        </Box>
      ))}
    </Flex>
  )
}

const JobView = ({ job }: { job: Job }) => {
  const $jobSkills = <JobSkills skills={job.skills} />

  let $jobInfo = null
  if (job.roles && job.roles.length > 1) {
    $jobInfo = (
      <Grid
        templateRows="repeat(2,min-content)"
        templateColumns="repeat(3,min-content)"
        fontSize="sm"
        color="gray.600"
        marginBottom={2}
      >
        {job.roles.map((role, idx) => (
          <React.Fragment key={idx}>
            <DateRange
              whiteSpace="nowrap"
              range={role.period}
              format="year-mon"
            />
            <Text marginX={1} />
            <Text whiteSpace="nowrap">{role.title}</Text>
          </React.Fragment>
        ))}
      </Grid>
    )
  }
  return (
    <Box>
      <Box>
        <Flex fontSize={{ base: "xl", md: "3xl" }} flexDirection="row" mb={0}>
          <Text fontWeight="light" fontFamily="Work Sans">
            {job.title}
          </Text>
        </Flex>
        <Divider borderColor="gray.300" marginBottom={2} />
        <Flex
          flexDirection="column"
          justifyContent={{ md: "flex-end" }}
          fontSize="sm"
        >
          <Link href={job.company.link}>{job.company.name}</Link>
          <DateRange range={job.period} format="year-mon" />
          <Text fontSize={{ base: "xs", md: "unset" }}>
            {job.location.city}, {job.location.countryCode}
          </Text>
        </Flex>
        {$jobInfo}
      </Box>
      <Text pt={2}>{job.description}</Text>
      {$jobSkills}
    </Box>
  )
}

export default ({
  experience,
  ...props
}: { experience: Experience } & BoxProps): React.ReactElement => {
  const jobs = experience.jobs
  const $jobs = jobs.map((job, idx) => <JobView key={idx} job={job} />)
  return (
    <Box {...props}>
      <Text mb={4} fontSize="2xl">
        {"Professional Experience".toUpperCase()}
      </Text>
      {$jobs}
    </Box>
  )
}
