import { Box, Flex, FlexProps, Heading, Icon, Text } from "@chakra-ui/react"
import Email from "components/ui/Email"
import Link from "components/ui/Link"
import { PersonalInfo } from "data/cv/model"
import React from "react"
import { ImFilePdf } from "react-icons/im"
import { MdEmail, MdMyLocation } from "react-icons/md"

interface Props {
  personalInfo: PersonalInfo
}

const Header = ({ personalInfo, ...props }: Props & FlexProps) => {
  const pi = personalInfo

  const iconTextProps = { marginLeft: 3 }
  const IconContainer = ({ children }: { children: React.ReactNode }) => (
    <Flex
      flexDirection="row"
      alignSelf="flex-end"
      marginBottom={1}
      alignItems="center"
      fontSize="sm"
    >
      {children}
    </Flex>
  )

  return (
    <Flex
      flexWrap={{ base: "wrap", md: "nowrap" }}
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-between"
      {...props}
    >
      <Box>
        <Heading
          fontWeight={300}
          fontSize="5xl"
          mb={{ base: 10, md: 8 }}
        >
          {pi.fullName}
        </Heading>
        <Text fontSize="lg">{pi.summary}</Text>
      </Box>
      <Flex direction="column">
        <IconContainer>
          <Icon as={MdMyLocation} />
          <Text {...iconTextProps}>
            {pi.location.city}, {pi.location.country}
          </Text>
        </IconContainer>
        <IconContainer>
          <Icon as={MdEmail} />
          <Email email={pi.email} {...iconTextProps} color="black" />
        </IconContainer>
        <IconContainer>
          <Icon as={ImFilePdf} />
          <Link
            color="black"
            fontWeight={600}
            {...iconTextProps}
            href="/resume.pdf"
          >
            Get PDF
          </Link>
        </IconContainer>
      </Flex>
    </Flex>
  )
}

export default Header
