import ExperienceSection from "components/cv/Experience"
import Header from "components/cv/Header"
import HeaderBg from "components/layout/HeaderBg"
import Layout from "components/layout/Layout"
import SEO from "components/layout/SEO"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { experience, personalInfo } from "data/cv/data"
import { PageProps } from "gatsby"
import * as React from "react"

const Resume = ({ location }: PageProps) => {
  return (
    <Layout backgroundColor="white" location={location}>
      <SEO title="MN: Resume"/>
      <HeaderBg bgColor="white" h={12} />
      <MaxWidthWrapper pt={{ base: 0, md: 16 }}>
        <Header personalInfo={personalInfo} marginBottom={8} />
        <ExperienceSection experience={experience} />
      </MaxWidthWrapper>
    </Layout>
  )
}

export default Resume
